<template>
  <Layout>
    <PageHeader :items="items"/>
    <b-modal v-model="importModal" title="Import New Data" @ok="submitCashSales">
      <div v-if="isLoading == 1" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Please wait...</strong>
      </div>
      <div class="row">
        <div class="col-md-12 mb-2">
          <label class="form-label">Corporate Branch*</label>
          <multiselect
              v-model="corporateBranch"
              :options="corporateBranchArr"
              :show-labels="false"
              label="corpBranchName"
              track-by="corpBranchID"
          ></multiselect>
        </div>
        <div class="col-md-12 mb-2">
                  <label class="form-label">Restaurant Branch*</label>
                  <multiselect
                        v-model="popupBranch"
                        :options="popupBranches"
                        :show-labels="false"
                        label="restBranchName"
                        track-by="restBranchID"
                    ></multiselect>
                </div>
        <div class="col-md-12 mb-2">
          <label>Enter Cash Amount</label>
          <div class="form-group">
            <input v-model="net_amount" class="form-control" placeholder="Please enter cash amount" type="text">
          </div>
        </div>

        <div class="col-md-12 mb-2">
          <label>Enter No. of Transactions </label>
          <div class="form-group">
            <input v-model="total_transactions" class="form-control" placeholder="Please enter total transactions"
                   type="text">
          </div>
        </div>
        <div class="col-md-12 mb-2">
          <label>Transaction Date</label>
          <div class="form-group">
            <input v-model="transaction_date" class="form-control"
                   placeholder="Please select transaction date"
                   type="date">
          </div>
        </div>

      </div>

    </b-modal>
    <!--FILTER START------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">

        <div class="inner mb-2 row">
          <!-- Country List-->
          <div class="col-md-2" style="width: 12%">
            <label>Country </label>
            <multiselect
                v-model="country"
                :options="countryArr"
                :show-labels="false"
                label="country"
                track-by="country"
                @input="getAllCities()"
            ></multiselect>
          </div>

          <div class="col-md-2" style="width: 12%">
            <label>City </label>
            <multiselect
                v-model="city"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="getAllCompanies()"
            ></multiselect>
          </div>
          <div class="col-md-3">
            <label class="form-label" style="font-size: 12px"
            >Select Company
            </label>
            <multiselect
                v-model="companyID"
                :options="companyArr"
                :show-labels="false"
                label="companyName"
                track-by="companyID"
                @input="getRestaurantBrands()"
            ></multiselect>
          </div>
          <div class="col-md-2">
            <label class="form-label" style="font-size: 12px"
            >Select Restaurant Brand
            </label>
            <multiselect
                v-model="restID"
                :options="brandArr"
                :show-labels="false"
                label="name"
                track-by="restID"
                @input="getBranches()"
            ></multiselect>
          </div>
          <div class="col-md-3">
            <label>Restaurant Branch </label>
            <multiselect
                v-model="branchID"
                :options="restaurantBranches"
                :show-labels="false"
                label="branchName"
                track-by="restBranchID"
            ></multiselect>
          </div>
        </div>
        <div class="inner mb-2 row">
          <!-- Date range -->
          <div class="col-md-3">
            <label>Date Range </label>
            <date-picker
                v-model="daterange"
                append-to-body
                confirm
                format="DD MMM Y"
                lang="en"
                value-type="format"
                range
            ></date-picker>
          </div>

          <!-- Order Type END-->

          <!-- Apply filter -->
          <div class="col-md-3" style="width: auto; margin-top: auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-3">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <div class="col-md-3 mt-4">
            <button class="btn btn-themeYellow float-end" @click="importModal=!importModal">
              <i class="uil-plus"></i> Add Record </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div v-if="loading == 1" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading Please wait...</strong>
          </div>
          <b-tabs
              content-class="p-3 text-muted"
              justified
              nav-class="nav-tabs-custom"
          >
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                >Cash Sales Report Overview</span
                >
              </template>

              <div class="row" style="margin: 10px 0 0">
                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ overViewData.total_sum ? $func.formatPrice(overViewData.total_sum) : 0 }}
                          <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="(overViewData.total_sum) ? overViewData.total_sum : 0"  :duration="2000"></countTo>
                          </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{ overViewData.total_orders ? $func.formatCommaSepNum(overViewData.total_orders) : 0 }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{ overViewData.average_order_sale ? $func.formatPrice(overViewData.average_order_sale) : 0 }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2"></div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ overViewData.total_gst ? $func.formatPrice(overViewData.total_gst) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">GST</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{ overViewData.total_orders ? $func.formatCommaSepNum(overViewData.total_orders) : 0 }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{ overViewData.average_gst ? $func.formatPrice(overViewData.average_gst) : 0 }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Cash Sales Report List</span>
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div
                          class="col-sm-12 col-md-4"
                          style="margin-bottom: 5px"
                      >
                        <div class="btn-group" role="group">
                          <button
                              v-if="this.$storageData.profile.empTypeID == 1"
                              class="btn btn-themeBrown"
                              type="button"
                              @click="downloadSample()"
                          >
                            Download Excel
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4 ">
                        <div
                            id="tickets-table_length"
                            class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                                style="margin-left: 5px; margin-right: 5px"
                                @input="getTransactions(1);"
                            ></b-form-select
                            >&nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                          :busy="isReportTableBusy"
                          :fields="fields"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          :items="tableItems"
                          :per-page="0"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          bordered
                          hover
                          outlined
                          responsive
                          striped
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template v-slot:cell(transaction_date)="data">
                          <span> {{ data.item.transaction_date }}</span>
                        </template>
                        <template v-slot:cell(restaurantName)="data">
                          <span> {{ data.item.restaurantName }}</span>
                        </template>
                        <template v-slot:cell(branchName)="data">
                          <span>
                            {{ data.item.branchName }}</span
                          >
                        </template>
                        <template v-slot:cell(total_transaction)="data">
                          <span>
                            {{ data.item.total_transaction }}</span
                          >
                        </template>
                        <template v-slot:cell(net_amount)="data">
                          <span>
                            {{ data.item.net_amount }}</span
                          >
                        </template>
                        <template v-slot:cell(gst )="data">
                          <span>
                            {{ data.item.gst }}</span
                          >
                        </template>
                        <template v-slot:cell(totla_amount)="data">
                          <span>
                            {{ data.item.totla_amount }}</span
                          >
                        </template>
                        <template v-slot:cell(update_by)="data">
                          <span>
                            {{ data.item.update_by }}</span
                          >
                        </template>
                        <template v-slot:cell(updated_at)="data">
                          <span>
                            {{ data.item.updated_at }}</span
                          >
                        </template>

                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                            class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-end
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <b-pagination v-model="currentPage" :per-page="tableTo" :total-rows="tableTotal"
                                          size="md"></b-pagination>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config.json";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
  name: "CashSalesReport",
  page: {
    title: "Cash Sales Reports",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {Layout, PageHeader,Multiselect,DatePicker},
  data() {
    return {
      corporate_branch_code:"",
      net_amount:0,
      total_transactions:0,
      transaction_date:"",
      isReportTableBusy: false,
      overViewData: [],
      tableData: [],
      tableTotal: '',
      tableTo: '',
      tableItems: [],
      tableExcelData: [],
      title: "Cash Sales",
      items: [
        {
          text: "View",
        },
        {
          text: "Cash Sales",
          active: true,
        },
      ],
      importModal:false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      restaurantBranches: [],
      branchID: "",
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      companyArr:[],
      companyID:"",
      brandArr:[],
      restID:"",
      popupBranches: [],
      popupBranch:"",
      isLoading: 0,
      loading:0,
      encodeDocument:"",
      fields: [
        {
          key: "transaction_date",
          label: "Date",
          sortable: true,
        },
        {
          key: "corporate_branch_code",
          label: "Corporate Branch Code",
          sortable: true,
          tdClass: "align-right",
        }, 
        {
          key: "restaurantName",
          label: "Restaurant Name",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "branchName",
          label:"Branch Name",
          sortable: true,
        },
        {
          key: "total_transaction",
          label:"No. of Transaction",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "net_amount",
          label:"Net Amount",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "gst",
          label:"GST 5%",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "total_amount",
          label:"Total Amount",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "updated_by",
          label:"Updated By",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "updated_at",
          label:"Updated On",
          sortable: true,
          tdClass: "align-right",
        },
      ],
      corporateBranchArr:[],
      corporateBranch:"",
    };
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getAllCountry();
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    currentPage: function (newVal) {
      this.viewSalesReportPerPage(newVal);
    },
    importModal : function(){
            if(this.importModal==true && this.popupBranches.length==0){
              this.getCorporateBranchList();
                this.getRestaurantBranches();
              }
          }
  },
  methods: {
    getRestaurantBranches(){
            this.isLoading = 1;
            this.axios
                .get(this.$loggedRole+"/cash-sales/restaurant-branches")
                .then((response) => {
                    this.isLoading=0;
                        if(response.data.status==200) {
                            this.popupBranches=[];
                            this.popupBranch="";
                            this.popupBranches = response.data.data;
                          }
                        else {
                          this.$swal({
                            text: response.data.message,
                            icon: "error",
                          });

                          }
                  })
                .catch((error) => {
                    this.isLoading=0;
                         this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
                  });
          },
    getAllCountry() {
      this.loading=1;
      this.countryArr = [];
      this.country = "";
      this.axios
          .post(this.$loggedRole+"/getAllCountry", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.loading=0;
            this.countryArr = response.data.data;
          })
          .catch((error) => {
            this.loading=0;
                 this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
          });
    },
    getAllCities() {
      this.loading=1;
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.loading=0;
            this.cityArr = response.data.data;
          }).catch((error)=>{
        this.loading=0;
             this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
      });
    },
    getAllCompanies() {
      this.loading=1;
      this.companyArr = [];
      this.companyID = "";
      this.axios
          .post(this.$loggedRole+"/getAllCompanies", {
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.loading=0;
            this.companyArr = response.data.data;
          })
          .catch((error)=>{
            this.loading=0;
                 this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
          });
    },
    getRestaurantBrands() {
      this.loading=1;
      this.axios
          .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
            companyID: this.companyID,
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.loading=0;
            this.brandArr = response.data.data;
          })
          .catch((error)=>{
            this.loading=0;
                 this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
          });
    },
    getBranches(){
      if(this.country.length==0 || this.country=="undefined")
      {
        alert('Please select country.');
      }
      else if(this.city.length == 0 || this.city=="undefined"){
        alert('Please select city.')
      }
      else if(this.restID.length==0 || this.restID=="undefined"){
        alert('Please select restaurant brand.')
      }
      else {
        this.loading=1;
        this.axios
            .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
              restID: this.restID.restID,
              empTypeID: this.$storageData.profile.empTypeID,
              city: this.city ? this.city.city : "",
              country: this.country ? this.country.country : "",
              empID: this.$storageData.profile.pid,
              loginTypeID: this.$storageData.login_type,
            })
            .then((response) => {
              this.loading=0;
              if(response.data.status==200){
                this.restaurantBranches = response.data.data;
              }
              else {
                this.$swal({
                  text:response.data.message,
                  icon: "error",
                });
              }
            })
            .catch((error)=>{
              this.loading=0;
                   this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
            });
      }
    },

    submitCashSales(){
      this.isLoading = 1;
      let formData = new FormData();
      formData.append('corporate_branch_id',this.corporateBranch.corpBranchID);
      formData.append('restBranchID',this.popupBranch.restBranchID);
      formData.append('total_amount',this.net_amount);
      formData.append('total_transactions',this.total_transactions);
      formData.append('transaction_date',this.transaction_date);
      formData.append('employee_id',this.$storageData.profile.pid);
      this.axios.post(this.$loggedRole+'/cash-sales/add-data',  formData)
          .then((response) => {
            this.isLoading=0;
            this.importModal=false;
            this.net_amount=0;
            this.total_transactions=0;
            this.transaction_date="";
            if(response.data.status==200){
              this.$swal({
                icon:"success",
                text:response.data.message,
              });
            }
            else{
              this.$swal({
                text: response.data.message,
                icon: "error",
              });
            }
          })
          .catch((error)=> {
            this.isLoading=0;
                 this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
          });
    },
    applyFilter(){
      this.getTransactions(1);
    },
    viewSalesReportPerPage(currentPage){
      this.getTransactions(currentPage);
    },
    clearFilter(){
      this.tableData=[];
      this.tableTotal= '';
      this.tableTo= '';
      this.tableItems= [];
      this.filterOn= [];
      this.daterange= [];
      this.restaurantBranches= [];
      this.branchID= "";
      this.cityArr= [];
      this.city= "";
      this.country= "";
      this.companyArr=[];
      this.companyID="";
      this.brandArr=[];
      this.restID="";
      this.tableExcelData=[];
      this.overViewData=[];
    },
    getTransactions(page){
      this.loading = 1;
      this.isReportTableBusy = true;
      this.axios
          .post(this.$loggedRole+"/cash-sales/get-cash-transactions?page="+page, {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            restaurant_branch_id: this.branchID!==null ? this.branchID.restBranchID : "",
            daterange: this.daterange,
            loginTypeID: this.$storageData.login_type,
            perPage: this.perPage,
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : "",
          })
          .then((response) => {
            this.loading = 0;
            this.isReportTableBusy = false;
            if(response.data.status==200) {
              if (this.perPage != "All") {
                if (page === 1) {
                  this.tableData = response.data;
                  this.tableTotal = this.tableData.data.total;
                  this.tableTo = this.tableData.data.to;
                  this.overViewData = response.data.overviewData;
                }
                this.tableExcelData= this.tableItems = response.data.data.data;
              } else {
                this.tableExcelData= this.tableItems = response.data.data;
                this.overViewData = response.data.overviewData;
                this.tableTotal = 1;
                this.tableTo = 1;
              }
            }
            else{
              this.$swal({
                text:response.data.message,
                icon: "error",
              });
            }
          })
          .catch((error)=> {
            this.loading=0;
            this.isReportTableBusy = false;
                 this.$swal({
            text: error.response.data.message,
            icon: "error",
          });
          });
    },
    downloadSample() {
      this.isLoading=1;
      this.axios
          .post(this.$loggedRole+'/exportExcel',
              {
                exportData: this.tableExcelData,
                page: "Cash Sales",
              },
              {
                responseType: "blob",
              }
          )
          .then((response) => {
            this.isLoading=0;
            const url = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.ms-excel",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "cash-sales.xlsx");
            document.body.appendChild(link);
            link.click();
          });
    },
    getCorporateBranchList(){
      this.isLoading = 1;
      this.corporateBranchArr = [];
      this.corporateBranch = "";
      this.axios.get(this.$loggedRole+"/getCorporateBranchList"
           )
          .then(response => {
            this.isLoading=0;
            this.corporateBranchArr = response.data.data;
          })
          .catch((error)=>{
            this.isLoading=0;
            this.$swal({
              icon:"error",
              text:error.response.data.message,
            });
          });
    },
  }
}
</script>